export const FirebaseConfig = {
	"projectId": "oneapp-376511",
	"appId": "1:12410008708:web:c526b1e199ee70e6137ed1",
	"databaseURL": "https://oneapp-376511-default-rtdb.firebaseio.com",
	"storageBucket": "oneapp-376511.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyAvmRnLKyY4i5sBvFKRfC3TUsGQtA8_XUo",
	"authDomain": "oneapp-376511.firebaseapp.com",
	"messagingSenderId": "12410008708",
	"measurementId": "G-WHDQFP3ZDR"
};